import React, {useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
import {useSelector} from "react-redux";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import LayersIcon from "@mui/icons-material/Layers";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ShuffleOutlinedIcon from "@mui/icons-material/ShuffleOutlined";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import StorageIcon from "@mui/icons-material/Storage";
import TableViewIcon from "@mui/icons-material/TableView";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import {Link} from "react-router-dom";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {BLACK_LABEL_UX, GRAY_LABEL_UX, WHITE_ESCRITA_THEME, BLUE_THEME,YELLOW_BG_UX,
	GRAY_BG_UX, DIVIDER_COLOR, GRAY_LABEL_UX_TEMA, BLACK_SUBITEM_NAVBAR, WHITE_SUBITEM_NAVBAR, RED_NEGATIVE_UX} from "../../shared/utils.js";
import "./listItem.css";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import RateReviewIcon from "@mui/icons-material/RateReview";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const ListItems = (props) => {
	const location = useLocation();
	const {open, isPerfil, setIsPerfil, className, setIsMenuOpen, isDesktop, mesesIfood} = props;
	const [activeButton, setActiveButton] = useState("dashboard");
	const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
	const isManager = useSelector(state => state.AuthReducer.isManager);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const storeId = useSelector(state => state.StoreReducer.storeId);
	const infoArray = ["dre", "dfc", "painel", "consolidada", "compras", "cmv", "fluxo_caixa"];
	const concArray = ["portais", "conciliacao_taxa", "resumo_ifood"];
	const arqArray = ["ata", "base_dados"];
	const dreIndexTour = (isAdmin || isManager) ? 1 : 0;
	const indexTour = (isAdmin || isManager) ? 0 : 1;

	useEffect(() => {
		const path = location.pathname.split("/")[1];
		if (infoArray.includes(path)) {
			props.setOpenSubItemInfo(true);
		} else if (concArray.includes(path)) {
			props.setOpenSubItemConc(true);
		} else if (arqArray.includes(path)) {
			props.setOpenSubItemArq(true);
		}
	}, []);

	useEffect(() => {
		let path = location.pathname.replace("/", "");
		setActiveButton(path);
		const query = new URLSearchParams(location.search);
		const table = query.get("table");
		const lowercaseTable = table ? table.toLowerCase() : "";
		if (path === "detalha_ca") {
			setActiveButton(lowercaseTable);
		}
	}, []);

	useEffect(() => {
		const localCurrent = window.location.pathname;
		if (activeButton == "dre" && localCurrent == "/dre") {
			const url = `/${activeButton}`;
			const params = {store_id: storeId};
			const queryString = new URLSearchParams(params).toString();
			window.history.replaceState(storeId, "", `${url}?${queryString}`);
		}
	}, [storeId]);

	useEffect(() => {
		let path = location.pathname.replace("/", "");
		if (path !== "perfil") {
			setIsPerfil(false);
		}
		if (path === "dre_consolidada") {
			setActiveButton("dreconsolidada");
		}
		if (path === "dre") {
			setActiveButton("dre");
		}
		if (path === "painel") {
			setActiveButton("painel");
		}
		if (path === "extrato_movimentacoes") {
			setActiveButton("extrato_movimentacoes");
		}
		if (path === "plano_contas") {
			setActiveButton("plano_contas");
		}
	}, [location]);

	const styleOpen = () => {
		if (open) {
			return styles.ListItem;
		} else {
			return styles.ListItemAlt;
		}
	};

	const styleColor = (pathList) => {
		if (isPerfil) {
			return;
		}
		if(temaEscuro===true){
			return {color: pathList.includes(activeButton) ? BLACK_LABEL_UX : WHITE_ESCRITA_THEME};
		}else{
			return {color: pathList.includes(activeButton) ? BLACK_LABEL_UX : GRAY_LABEL_UX};
		}
	};

	const styleSelected = (pathList, isSubItem) => {
		if (isPerfil) {
			return;
		}
		if (isSubItem) {
			if(temaEscuro===true){
				return {backgroundColor: pathList.includes(activeButton) ? YELLOW_BG_UX : null};
			}else{
				return {backgroundColor: pathList.includes(activeButton) ? YELLOW_BG_UX : null};
			}

		} else {
			if(temaEscuro===true){
				return {backgroundColor: pathList.includes(activeButton) ? YELLOW_BG_UX : BLUE_THEME};
			}else{
				return {backgroundColor: pathList.includes(activeButton) ? YELLOW_BG_UX : GRAY_BG_UX};
			}
		}
	};

	const handleClick = (local) => {
		if (local === "info") {
			props.setOpenSubItemInfo(!props.openSubItemInfo);
		} else if (local === "conc") {
			props.setOpenSubItemConc(!props.openSubItemConc);
		} else {
			props.setOpenSubItemArq(!props.openSubItemArq);
		}
	};

	const handleChangeClick = (local) => {
		setActiveButton(local);
		if (!isDesktop) {
			setIsMenuOpen(false);
		}
	};

	return (
		<React.Fragment>
			<Link to="/dashboard" className="text-link" role="item">
				<ListItemButton
					style={{
						...styleOpen(),
						...styleSelected(["dashboard", ""]),
					}}
					onClick={() => handleChangeClick("dashboard")}
					className={className[0]}
				>
					<ListItemIcon>
						<HomeOutlinedIcon style={styleColor(["dashboard", ""])}/>
					</ListItemIcon>
					<ListItemText
						primary={
							<Typography
								component="span"
								style={styleColor(["dashboard", ""])}
							>
								Resumo
							</Typography>
						}
					/>
				</ListItemButton>
			</Link>
			<ListItemButton onClick={() => handleClick("info")} style={{color: YELLOW_BG_UX, marginTop: "15px"}} className="style_icon_subitens" data-testid="collapse">
				{props.openSubItemInfo ?  <ExpandMore /> : <KeyboardArrowRightIcon />}
				{
					open && (<ListItemText primary="Info. Gerenciais" />)
				}
			</ListItemButton>
			<Collapse in={props.openSubItemInfo} timeout="auto" unmountOnExit sx={{backgroundColor: temaEscuro ? BLACK_SUBITEM_NAVBAR : WHITE_SUBITEM_NAVBAR}}>
				<List component="div" className="container-list-subitens" sx={{padding: "15px 0"}}>
					<Link to={`/dre?store_id=${storeId}`} className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["dre"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("dre")}
							className={className[1 + dreIndexTour]}
						>
							<ListItemIcon>
								<ListAltOutlinedIcon
									style={styleColor(["dre"])}
								/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["dre"])}
									>
										DRE
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
					<Link to="/dfc" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["dfc"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("dfc")}
							className={className[3 - indexTour]}
						>
							<ListItemIcon>
								<ListAltOutlinedIcon
									style={styleColor(["dfc"])}
								/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["dfc"])}
									>
										DFC
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
					<Link to="/painel" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["painel"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("painel")}
							className={className[4 - indexTour]}
						>

							<ListItemIcon>
								<LayersIcon
									style={styleColor(["painel"])}/>
								{/* cor do icone painel */}
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["painel"])}
									>
										Painel
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
					<Link to="/dre_consolidada" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["dreconsolidada"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("dreconsolidada")}
							className={className[5 - indexTour]}
						>
							<ListItemIcon>
								<TableViewIcon
									style={styleColor(["dreconsolidada"])}/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["dreconsolidada"])}
									>
										DRE Consolidada
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
					<Link to="/comparativo" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["comparativo"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("comparativo")}
							className={className[6 - indexTour]}
						>
							<ListItemIcon>
								<TableViewIcon
									style={styleColor(["comparativo"])}/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["comparativo"])}
									>
										Comparativo
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
					<Link to="/compras" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["compras"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("compras")}
							className={className[7 - indexTour]}
						>
							<ListItemIcon>
								<RequestQuoteIcon
									style={styleColor(["compras"])}/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["compras"])}
									>
										Compras
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
					<Link to="/cmv" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["cmv"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("cmv")}
							className={className[7 - indexTour]}
						>
							<ListItemIcon>
								<ShoppingCartIcon
									style={styleColor(["cmv"])}/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["cmv"])}
									>
										CMV
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
					<Link to="/fluxo_caixa" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["fluxo_caixa"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("fluxo_caixa")}
							className={className[8 - indexTour]}
						>
							<ListItemIcon>
								<AttachMoneyOutlinedIcon
									style={styleColor(["fluxo_caixa"])}/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["fluxo_caixa"])}
									>
										Fluxo de Caixa
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
					<Link to="/extrato_movimentacoes" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["extrato_movimentacoes"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("extrato_movimentacoes")}
							className={className[9 - indexTour]}
						>
							<ListItemIcon>
								<AccountBalanceIcon
									style={styleColor(["extrato_movimentacoes"])}/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["extrato_movimentacoes"])}
									>
										EXM
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
				</List>
			</Collapse>
			<ListItemButton onClick={() => handleClick("conc")} sx={{color: YELLOW_BG_UX}} className="style_icon_subitens" data-testid="collapse">
				{props.openSubItemConc ? <ExpandMore /> : <KeyboardArrowRightIcon />}
				{
					// add special character to avoid conflict in tests with the word conciliação
					open && (<ListItemText primary={" Conciliação\u200B"} />)
				}
			</ListItemButton>
			<Collapse in={props.openSubItemConc} timeout="auto" unmountOnExit sx={{backgroundColor: temaEscuro ? BLACK_SUBITEM_NAVBAR : WHITE_SUBITEM_NAVBAR}}>
				<List component="div" className="container-list-subitens" sx={{padding: "15px 0"}}>
					<Link to="/portais" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["portais"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("portais")}
							className={className[10 - indexTour]}
						>
							<ListItemIcon>
								<ShuffleOutlinedIcon
									style={styleColor(["portais"])}
								/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["portais"])}
									>
										Portais
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
					<Link to="/conciliacao_taxa" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["conciliacao_taxa"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("conciliacao_taxa")}
							className={className[11 - indexTour]}
						>
							<ListItemIcon>
								<RateReviewIcon
									style={styleColor(["conciliacao_taxa"])}
								/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["conciliacao_taxa"])}
									>
										Taxas
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
					{mesesIfood && (
						<Link to="/resumo_ifood" className="text-link" role="item">
							<ListItemButton
								style={{
									...styleOpen(),
									...styleSelected(["resumo_ifood"], true),
									marginLeft: 0
								}}
								onClick={() => handleChangeClick("resumo_ifood")}
								className={className[12 - indexTour]}
							>
								<ListItemIcon>
									<DeliveryDiningIcon
										style={styleColor(["resumo_ifood"])}
									/>
								</ListItemIcon>
								<ListItemText
									primary={
										<Typography
											component="span"
											style={styleColor(["resumo_ifood"])}
										>
											<p style={{display: "flex", alignItems: "center", gap: 3}}>Resumo iFood <strong style={{backgroundColor: RED_NEGATIVE_UX, borderRadius: "5px", padding: "1px 5px", color: WHITE_ESCRITA_THEME, fontSize: "12px"}}>BETA</strong></p>
										</Typography>
									}
								/>
							</ListItemButton>
						</Link>
					)}
				</List>
			</Collapse>
			<ListItemButton onClick={() => handleClick("arq")} style={{color: YELLOW_BG_UX}} className="style_icon_subitens" data-testid="collapse">
				{props.openSubItemArq ? <ExpandMore /> : <KeyboardArrowRightIcon />}
				{
					open && (<ListItemText primary="Arquivos" />)
				}
			</ListItemButton>
			<Collapse in={props.openSubItemArq} timeout="auto" unmountOnExit sx={{backgroundColor: temaEscuro ? BLACK_SUBITEM_NAVBAR : WHITE_SUBITEM_NAVBAR}}>
				<List component="div" className="container-list-subitens" sx={{padding: "15px 0"}}>
					<Link to="/ata" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["ata"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("ata")}
							className={className[13 - indexTour]}
						>
							<ListItemIcon>
								<SmsOutlinedIcon
									style={styleColor(["ata"])}/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["ata"])}
									>
										Ata de Reunião
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
					<Link to="/base_dados" className="text-link" role="item">
						<ListItemButton
							style={{
								...styleOpen(),
								...styleSelected(["base_dados"], true),
								marginLeft: 0
							}}
							onClick={() => handleChangeClick("base_dados")}
							className={className[14 - indexTour]}
						>
							<ListItemIcon>
								<StorageIcon
									style={styleColor(["base_dados"])}/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										component="span"
										style={styleColor(["base_dados"])}
									>
										Base de Dados
									</Typography>
								}
							/>
						</ListItemButton>
					</Link>
				</List>
			</Collapse>
			{
				(isAdmin || isManager) && (
					<React.Fragment>
						{
							open ? (
								<div data-testid="line">
									<Divider sx={{...styles.divider, color: temaEscuro ? GRAY_LABEL_UX_TEMA : DIVIDER_COLOR}} />
									<h1 style={{...styles.Administrativo, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Administrativo</h1>
								</div>
							) : (
								<div>
									<Divider sx={{...styles.divider, color: temaEscuro ? GRAY_LABEL_UX_TEMA : DIVIDER_COLOR}} />
								</div>
							)
						}
						<Link to="/plano_contas" className="text-link" role="item">
							<ListItemButton
								style={{
									...styleOpen(),
									...styleSelected(["plano_contas"]),

								}}
								onClick={() => handleChangeClick("plano_contas")}
								className={className[15 - indexTour]}
							>
								<ListItemIcon>
									<WidgetsOutlinedIcon
										style={styleColor(["plano_contas"])}/>
								</ListItemIcon>
								<ListItemText
									primary={
										<Typography
											component="span"
											style={styleColor(["plano_contas"])}
										>
											Plano de Contas
										</Typography>
									}
								/>
							</ListItemButton>
						</Link>
						{
							(isAdmin && !isManager) && (
								<React.Fragment>
									<Link to="/logins" className="text-link" role="item">
										<ListItemButton
											style={{
												...styleOpen(),
												...styleSelected(["logins"]),
											}}
											onClick={() => handleChangeClick("logins")}
											className={className[16 - indexTour]}
										>
											<ListItemIcon>
												<VpnKeyIcon
													style={styleColor(["logins"])}/>
											</ListItemIcon>
											<ListItemText
												primary={
													<Typography
														component="span"
														style={styleColor(["logins"])}
													>
														Logins
													</Typography>
												}
											/>
										</ListItemButton>
									</Link>
									<Link to="/resolucao" className="text-link" role="item">
										<ListItemButton
											style={{
												...styleOpen(),
												...styleSelected(["resolucao"]),
											}}
											onClick={() => handleChangeClick("resolucao")}
											className={className[17 - indexTour]}
										>
											<ListItemIcon>
												<SettingsSuggestIcon
													style={styleColor(["resolucao"])}/>
											</ListItemIcon>
											<ListItemText
												primary={
													<Typography
														component="span"
														style={styleColor(["resolucao"])}
													>
														Resolução
													</Typography>
												}
											/>
										</ListItemButton>
									</Link>
								</React.Fragment>

							)
						}
					</React.Fragment>
				)
			}
		</React.Fragment>
	);
};

const styles = {
	ListItem: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		padding: "10px 0px 10px 8px",
		width: "208px",
		height: "44px",
		borderRadius: "8px",
		flex: "none",
		order: "0",
		flexGrow: "0",
		marginLeft: "10px",
	},

	ListItemAlt: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		padding: "10px 0px 10px 43px",
		gap: "12px",
		width: "40px",
		height: "40px",
		borderRadius: "8px",
		flex: "none",
		order: "0",
		flexGrow: "0",
		marginLeft: "8px",
	},

	Administrativo: {
		width: "133px",
		height: "24px",
		fontFamily: "Inter",
		fontStyle: "normal",
		fontWeight: "500",
		fontSize: "16px",
		lineHeight: "24px",
		color: "#2D3748",
		flex: "none",
		order: "0",
		flexGrow: "0",
		marginLeft: "20px",
	},
	divider: {
		marginLeft: "20px",
		marginRight: "20px",
		borderColor: "unset",
		my: 2
	},
	styleIcon: {
		color: BLACK_LABEL_UX
	},
};

export default ListItems;
