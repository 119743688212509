import axios from "axios";
import Cookies from "js-cookie";

const instance = axios.create({
	// eslint-disable-next-line no-undef
	baseURL: process.env.REACT_APP_API,
	headers: {
		"Content-Type": "application/json"
	}
});

const instanceFormData = axios.create({
	// eslint-disable-next-line no-undef
	baseURL: process.env.REACT_APP_API,
	headers: {
		"Content-Type": "multipart/form-data",
	}
});

instanceFormData.interceptors.request.use(config => {
	let token = Cookies.get("tk");
	if (token) {
		config.headers.Authorization = `Token ${token}`
			.replace(/(^)|($)/g, "");
	}
	return config;
}, err => {
	return Promise.reject(err);
});

instance.interceptors.request.use(config => {
	let token = Cookies.get("tk");
	if (token) {
		config.headers.Authorization = `Token ${token}`
			.replace(/(^)|($)/g, "");
	}
	return config;
}, err => {
	return Promise.reject(err);
});

export default {

	// ======== ATA =========
	GetAta(storeId, ano, mes){
		return instance.get(`ata/${storeId}`, {
			params: {
				ano,
				mes
			}
		});
	},
	UpdateAta(storeId, data){
		return instanceFormData.post(`ata/${storeId}`, data);
	},
	DeleteAta(storeId, data){
		return instance.delete(`ata/${storeId}`, {params: data});
	},

	// ======== AUTENTICATION ========
	GetLogin(data) {
		return instance.post("auth/login", data);
	},
	SendResetPwdEmail(data){
		return instance.post("auth/email_pwd_code", data);
	},
	GetCheckCode(data){
		return instance.get("auth/email_pwd_code", {params: data});
	},
	GetResetPwd(data){
		return instance.put("auth/reset_pwd", data);
	},
	Logout(data) {
		return instance.delete("auth/logout", data);
	},
	PutChangePassword(data) {
		return instance.put("auth/change_pwd", data);
	},
	GetSession(){
		return instance.get("auth/active_session");
	},
	DeleteSession(data){
		return instance.delete("auth/active_session", {data:data});
	},
	PutUpdateDeviceToken(data){
		return instance.put("auth/update_device_token", data);
	},

	// ======== BASE DE DADOS ========

		// DRE, CONSOLIDADA and PROJETADA
		InsertFaturamentoDRE(data){
			return instance.post("base_dados/faturamento_dre", data);
		},
		DREMetadata(data) {
			return instance.post("base_dados/dre/metadata", data);
		},
		editCommentDRE(data){
			return instance.patch("base_dados/dre/metadata", data);
		},
		GetDRE(data){
			return instance.post("base_dados/dre", data);
		},
		PutDREProvision(data){
			return instance.put("base_dados/dre", data);
		},
		ExportDreExcel(data){
			return instance.post("base_dados/export_dre_excel", data);
		},
		delMetadataDRE(data){
			return instance.delete("base_dados/dre/metadata", {data: data});
		},
		RefreshDRE(data){
			return instance.patch("base_dados/dre", data);
		},
		GetAtualizacoesDRE(data){
			return instance.get("base_dados/save_dre", {params: data});
		},
		PostSaveDRE(data){
			return instance.post("base_dados/save_dre", data);
		},
		DeleteSaveDRE(data){
			return instance.delete("base_dados/save_dre", {data:data});
		},
		GetDREConsolidada(data){
			return instance.post("base_dados/dre_consolidada", data);
		},
		RefreshDREConsolidada(data){
			return instance.patch("base_dados/dre_consolidada", data);
		},
		PostDREProjetada(data){
			return instance.post("base_dados/dre_projetada", data);
		},
		GetDREProjetada(data){
			return instance.get("base_dados/dre_projetada", {params: data});
		},
		SendDREMessage(data){
			return instance.post("base_dados/send_dre", data);
		},
		SendDREMeeting(data){
			return instance.patch("base_dados/send_dre", data);
		},
		
		// DFC
		editCommentDFC(data){
			return instance.patch("base_dados/dfc/metadata", data);
		},
		DFCMetadata(data) {
			return instance.post("base_dados/dfc/metadata", data);
		},
		GetDFC(data){
			return instance.post("base_dados/dfc", data);
		},
		delMetadataDFC(data){
			return instance.delete("base_dados/dfc/metadata", {data: data});
		},
		CommentDFC(data){
			return instance.put("base_dados/dfc/metadata", data);
		},
		RefreshDFC(data){
			return instance.patch("base_dados/dfc", data);
		},

		// COMPRAS
		GetCompras(data){
			return instance.post("base_dados/compras", data);
		},
		GetDashCompras(data){
			return instance.post("base_dados/dash_compras", data);
		},
		GetDashCMV(data){
			return instance.post("base_dados/dash_cmv", data);
		},
		PatchCMVFaturamentos(data){
			return instance.patch("base_dados/dash_cmv", data);
		},

		// FLUXO_CAIXA 
		GetFluxoCaixa(data){
			return instance.post("base_dados/fluxo_caixa", data);
		},
		GetDetalhaFluxoCaixa(data){
			return instance.post("base_dados/detalha_fluxo", data);
		},
		ExportFluxoCaixa(data){
			return instance.post("base_dados/export_detalha_fluxo", data);
		},

		// PAINEL
		GetPainel(data){
			return instance.post("base_dados/painel", data);
		},
		RefreshPainel(data){
			return instance.patch("base_dados/painel", data);
		},
		PainelMetadata(data) {
			return instance.post("base_dados/painel/metadata", data);
		},
		editCommentPainel(data){
			return instance.patch("base_dados/painel/metadata", data);
		},
		delMetadataPainel(data){
			return instance.delete("base_dados/painel/metadata", {data: data});
		},

		// DASHBOARD
		getDashboard(data){
			return instance.post("base_dados/dashboard", data);
		},

		// PLANO_CONTAS
		GetPlanoContas(storeId){
			return instance.get(`base_dados/${storeId}/plano_contas`);
		},
		CopyPlanoContas(storeId, data){
			return instance.patch(`base_dados/${storeId}/plano_contas`, data);
		},
		AddPlanoContas(storeId, data){
			return instance.post(`base_dados/${storeId}/plano_contas`, data);
		},
		EditPlanoContas(storeId, data){
			return instance.put(`base_dados/${storeId}/plano_contas`, data);
		},
		DeletePlanoContas(storeId, data){
			return instance.delete(`base_dados/${storeId}/plano_contas`, {data:data});
		},
		GetPlanoContasCompras(storeId){
			return instance.get(`base_dados/${storeId}/plano_contas_compras`);
		},
		AddPlanoContasCompras(storeId, data){
			return instance.post(`base_dados/${storeId}/plano_contas_compras`, data);
		},
		CopyPlanoContasCompras(storeId, data){
			return instance.patch(`base_dados/${storeId}/plano_contas_compras`, data);
		},
		EditPlanoContasCompras(storeId, data){
			return instance.put(`base_dados/${storeId}/plano_contas_compras`, data);
		},
		DeletePlanoContasCompras(storeId, data){
			return instance.delete(`base_dados/${storeId}/plano_contas_compras`, {data:data});
		},
		RenameCategoriasPlanoContas(data){
			return instance.put("base_dados/rename_plano_categorias", data);
		},

		// COMPARATIVO
		getComparativo(data){
			return instance.post("base_dados/comparativo", data);
		},

		// EXTRATO MOVIMENTACOES
		GetExtratoMovimentacoes(data){
			return instance.post("base_dados/extrato_movimentacoes", data);
		},

		// Endpoint mais específicos 
		explodeCategoryCA(data){
			return instance.post("base_dados/explode_ca", data);
		},
		explodeCategoryIfood(data){
			return instance.post("base_dados/explode_ifood", data);
		},
		UpdateContaAzul(data){
			return instance.put("base_dados/run_conta_azul", data);
		},
		GetDownloads(storeId){
			return instance.get(`base_dados/${storeId}/downloads`);
		},
		GetDownloadLinks(data){
			return instance.post("base_dados/relatorios/downloads", data);
		},
		GetPlataformasDownloads(data){
			return instance.get("base_dados/relatorios/downloads", {params: data});
		},
		PostRelatorio(data){
			return instance.post("base_dados/relatorio", data);
		},
		sendReport(data){
			return instance.post("base_dados/change_logs", data);
		},
		SendUploadFile(loja_id, data){
			return instanceFormData.post(`base_dados/upload_relatorio/${loja_id}`, data);
		},
		CheckUploadFile(loja_id, data){
			return instance.put(`base_dados/upload_relatorio/${loja_id}`, data);
		},
		DeleteDataPlataforma(data){
			return instance.delete("base_dados/process_data", {data:data});
		},
		SendMessageETL(data){
			return instance.post("base_dados/process_data", data);
		},
		SendMessageCrawler(data){
			return instance.put("base_dados/process_data", data);
		},
		GetLastModification(storeId){
			return instance.get(`base_dados/${storeId}/ultima_modificacao`);
		},
		PostTenModification(storeId){
			return instance.post(`base_dados/${storeId}/ultima_modificacao`);
		},
		GetInsightsMercado(data){
			return instance.get("base_dados/insights_mercado", {params: data});
		},
		GetLatestRelease(){
			return instance.get("base_dados/latest_release");
		},
	
	// ======== CONCILIAÇÃO ========
	GetConciliacao(data){
		return instance.post("conciliacao/detail", data);
	},
	GetConciliacaoTaxa(data){
		return instance.post("conciliacao/detail_taxa", data);
	},
	FixConciliacaoField(data){
		return instance.post("conciliacao/metadata/portais", data);
	},
	editCommentConciliacao(data){
		return instance.patch("conciliacao/metadata/portais", data);
	},
	DeleteConciliacaoField(data){
		return instance.delete("conciliacao/metadata/portais", {data: data});
	},
	SendConciliacao(data){
		return instance.post("conciliacao/export_pdf", data);
	},
	PostSaveConciliacao(data){
		return instance.post("conciliacao/save_conciliacao", data);
	},
	DeleteSaveConciliacao(data){
		return instance.delete("conciliacao/save_conciliacao", {data:data});
	},
	GetMesesIfood(data){
		return instance.get("conciliacao/resumo_ifood", {params:data});
	},
	GetResumoIfood(data){
		return instance.post("conciliacao/resumo_ifood", data);
	},
	GetFaturamentoIfoodDetalhado(data){
		return instance.patch("conciliacao/resumo_ifood", data);
	},
	GetDetalharConciliacao(data){
		return instance.post("conciliacao/detalhar_conciliacao", data);
	},

	// ======== LOJA =========
	GetLojas(){
		return instance.get("loja/all");
	},
	GetMarcas(){
		return instance.get("loja/marca/all");
	},
	GetEstabelecimentos(data){
		return instance.get("loja/estabelecimentos/all", {params: data});
	},
	GetSegmentos(){
		return instance.get("loja/segmentos/all");
	},
	GetCidades(){
		return instance.get("loja/cidades/all");
	},
	GetMesesCompetencia(data){
		return instance.post("loja/meses_competencia", data);
	},

	// ======== PLATAFORMA ========
	GetLogins(data){
		return instance.get("plataforma/logins", {params: data});
	},
	GetIncorrectLogins(data){
		return instance.post("plataforma/logins", data);
	},
	GetPlataformas(){
		return instance.get("plataforma/all");
	},
	PostRegisterAPI(data){
		return instance.post("plataforma/cadastro_apis", data);
	},

	// ======== USER =========
	UserInfo(){
		return instance.get("user/detail");
	},
	GetUsers(){
		return instance.get("user/get_users");
	},
	PutDisableUpdateNews(){
		return instance.put("user/disable_updates");
	},
	UpdateUser(data){
		return instanceFormData.put("user/update_user", data);
	},

};
