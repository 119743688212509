import React  from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import {checkAuthLoader} from "./shared/auth";
import ChangePassword from "./containers/Auth/ChangePassword";
import Logins from "./containers/Logins/Logins";
import Painel from "./containers/Painel/Painel";
import DRE from "./containers/DRE/DRE";
import DREConsolidada from "./containers/DREConsolidada/DREConsolidada";
import DFC from "./containers/DFC/DFC";
import Portais from "./containers/Conciliacao/Portais/Portais";
import PlanoContas from "./containers/PlanoContas/PlanoContas";
import AddPlano from "./containers/PlanoContas/AddPlano";
import BaseDados from "./containers/BaseDados/BaseDados";
import Resolucao from "./containers/Resolucao/Resolucao";
import Ata from "./containers/Ata/Ata";
import AddAta from "./containers/Ata/AddAta";
import DetalhaCA from "./containers/DetalhaCA/DetalhaCA";
import DetalhaIfood from "./containers/DetalhaIfood/DetalhaIfood";
import FluxoCaixa from "./containers/FluxoCaixa/FluxoCaixa";
import UploadFile from "./components/UploadFile/UploadFile";
import Compras from "./containers/Compras/Compras";
import DetalhaFluxo from "./containers/FluxoCaixa/DetalhaFluxo";
import Historic from "./containers/DRE/Historic/Historic";
import Dashboard from "./containers/Dashboard/Dashboard";
import ForgotPwd from "./containers/Auth/ForgotPwd";
import CheckCode from "./containers/Auth/CheckCode";
import ResetPwd from "./containers/Auth/ResetPwd";
import Login from "./containers/Auth/Login";
import NavBar from "./components/NavBar/NavBar";
import Error from "./containers/Error/Error";
import Perfil from "./containers/Perfil/Perfil";
import API from "./containers/API/API";
import Comparativo from "./containers/Comparativo/Comparativo";
import PoliticaPrivacidade from "./containers/PoliticaPrivacidade/PoliticaPrivacidade";
import Insights from "./containers/Insights/Insights";
import ExtratoMovimentacoes from "./containers/ExtratoMovimentacoes/ExtratoMovimentacoes";
import ResumoIfood from "./containers/ResumoIfood/ResumoIfood";
import FaturamentoIfoodDetalhado from "./containers/ResumoIfood/ResumoIfoodComponents/FaturamentoIfoodDetalhado";
import ConciliacaoTaxa from "./containers/Conciliacao/ConciliacaoTaxa/ConciliacaoTaxa";
import CMV from "./containers/CMV/CMV";
import DREAtualizada from "./containers/DRE/DREAtualizada";
import Cielo from "./containers/Cielo/Cielo";


export const getRoutes = (dispatch,  token, isAdmin, resetarSenha, isCommonUser, isOperator, isManager) =>{
    let routes = [];

    //Rotas autenticadas
    if (token) {
        if (resetarSenha){
            routes = [
                {path: "mudar_senha", element: <ChangePassword />, loader: () => checkAuthLoader(dispatch)},
                {path: "*", element: <Navigate to="/mudar_senha" />},
            ];
        }
        else if(isAdmin){
            routes = [
                {path: "logins", element: <Logins />, loader: () => checkAuthLoader(dispatch)},
                {path: "painel", element: <Painel />, loader: () => checkAuthLoader(dispatch)},
                {path: "cielo", element: <Cielo />, loader: () => checkAuthLoader(dispatch)},
                {path: "dre", element: <DRE />, loader: () => checkAuthLoader(dispatch)},
                {path: "dre_consolidada", element: <DREConsolidada />, loader: () => checkAuthLoader(dispatch)},
                {path: "dfc", element: <DFC />, loader: () => checkAuthLoader(dispatch)},
                {path: "portais", element: <Portais />, loader: () => checkAuthLoader(dispatch)},
                {path: "conciliacao_taxa", element: <ConciliacaoTaxa />, loader: () => checkAuthLoader(dispatch)},
                {path: "resumo_ifood", element: <ResumoIfood />, loader: () => checkAuthLoader(dispatch)},
                {path: "faturamento_ifood_detalhado", element: <FaturamentoIfoodDetalhado />, loader: () => checkAuthLoader(dispatch)},
                {path: "plano_contas", element: <PlanoContas />, loader: () => checkAuthLoader(dispatch)},
                {path: "add_plano", element: <AddPlano />, loader: () => checkAuthLoader(dispatch)},
                {path: "base_dados", element: <BaseDados />, loader: () => checkAuthLoader(dispatch)},
                {path: "resolucao", element: <Resolucao />, loader: () => checkAuthLoader(dispatch)},
                {path: "ata", element: <Ata />, loader: () => checkAuthLoader(dispatch)},
                {path: "add_ata", element: <AddAta />, loader: () => checkAuthLoader(dispatch)},
                {path: "mudar_senha", element: <ChangePassword />, loader: () => checkAuthLoader(dispatch)},
                {path: "detalha_ca", element: <DetalhaCA />, loader: () => checkAuthLoader(dispatch)},
                {path: "detalha_ifood", element: <DetalhaIfood />, loader: () => checkAuthLoader(dispatch)},
                {path: "fluxo_caixa", element: <FluxoCaixa />, loader: () => checkAuthLoader(dispatch)},
                {path: "upload_file", element: <UploadFile />, loader: () => checkAuthLoader(dispatch)},
                {path: "compras", element: <Compras />, loader: () => checkAuthLoader(dispatch)},
                {path: "cmv", element: <CMV />, loader: () => checkAuthLoader(dispatch)},
                {path: "detalha_fluxo", element: <DetalhaFluxo />, loader: () => checkAuthLoader(dispatch)},
                {path: "historico_modificacoes", element: <Historic />, loader: () => checkAuthLoader(dispatch)},
                {path: "dashboard", element: <Dashboard />, loader: () => checkAuthLoader(dispatch)},
                {path: "perfil", element: <Perfil />, loader: () => checkAuthLoader(dispatch)},
                {path: "api", element: <API />, loader: () => checkAuthLoader(dispatch)},
                {path: "comparativo", element: <Comparativo />, loader: () => checkAuthLoader(dispatch)},
                {path: "insights", element: <Insights />, loader: () => checkAuthLoader(dispatch)},
                {path: "extrato_movimentacoes", element: <ExtratoMovimentacoes />, loader: () => checkAuthLoader(dispatch)},
                {path: "dre_atualizada", element: <DREAtualizada />, loader: () => checkAuthLoader(dispatch)},
                {path: "*", element: <Navigate to="/dashboard" />}
            ];
        }else{
            // Rotas genéricas para todos os usuários
            const common_routes = [
                {path: "logins", element: <Logins />, loader: () => checkAuthLoader(dispatch)},
                {path: "painel", element: <Painel />, loader: () => checkAuthLoader(dispatch)},
                {path: "dre", element: <DRE />, loader: () => checkAuthLoader(dispatch)},
                {path: "dre_consolidada", element: <DREConsolidada />, loader: () => checkAuthLoader(dispatch)},
                {path: "dfc", element: <DFC />, loader: () => checkAuthLoader(dispatch)},
                {path: "cielo", element: <Cielo />, loader: () => checkAuthLoader(dispatch)},
                {path: "api", element: <API />, loader: () => checkAuthLoader(dispatch)},
                {path: "portais", element: <Portais />, loader: () => checkAuthLoader(dispatch)},
                {path: "conciliacao_taxa", element: <ConciliacaoTaxa />, loader: () => checkAuthLoader(dispatch)},
                {path: "resumo_ifood", element: <ResumoIfood />, loader: () => checkAuthLoader(dispatch)},
                {path: "faturamento_ifood_detalhado", element: <FaturamentoIfoodDetalhado />, loader: () => checkAuthLoader(dispatch)},
                {path: "base_dados", element: <BaseDados />, loader: () => checkAuthLoader(dispatch)},
                {path: "ata", element: <Ata />, loader: () => checkAuthLoader(dispatch)},
                {path: "mudar_senha", element: <ChangePassword />, loader: () => checkAuthLoader(dispatch)},
                {path: "detalha_ca", element: <DetalhaCA />, loader: () => checkAuthLoader(dispatch)},
                {path: "detalha_ifood", element: <DetalhaIfood />, loader: () => checkAuthLoader(dispatch)},
                {path: "add_ata", element: <AddAta />, loader: () => checkAuthLoader(dispatch)},
                {path: "compras", element: <Compras />, loader: () => checkAuthLoader(dispatch)},
                {path: "cmv", element: <CMV />, loader: () => checkAuthLoader(dispatch)},
                {path: "fluxo_caixa", element: <FluxoCaixa />, loader: () => checkAuthLoader(dispatch)},
                {path: "detalha_fluxo", element: <DetalhaFluxo />, loader: () => checkAuthLoader(dispatch)},
                {path: "historico_modificacoes", element: <Historic />, loader: () => checkAuthLoader(dispatch)},
                {path: "dashboard", element: <Dashboard />, loader: () => checkAuthLoader(dispatch)},
                {path: "perfil", element: <Perfil />, loader: () => checkAuthLoader(dispatch)},
                {path: "comparativo", element: <Comparativo />, loader: () => checkAuthLoader(dispatch)},
                {path: "insights", element: <Insights />, loader: () => checkAuthLoader(dispatch)},
                {path: "extrato_movimentacoes", element: <ExtratoMovimentacoes />, loader: () => checkAuthLoader(dispatch)},
                {path: "*", element: <Navigate to="/dashboard" />}
            ];
            if (isCommonUser){
                routes = common_routes;
            }
            if(isManager) {
                routes = common_routes;
                routes.push(
                    {path: "plano_contas", element: <PlanoContas />, loader: () => checkAuthLoader(dispatch)},
                    {path: "add_plano", element: <AddPlano />, loader: () => checkAuthLoader(dispatch)},
                    {path: "dre_atualizada", element: <DREAtualizada />, loader: () => checkAuthLoader(dispatch)},
                );
            }
        }

    }else{
        routes = [
            {path: "forgot_pwd", element: <ForgotPwd />},
            {path: "check_code", element: <CheckCode />},
            {path: "reset_pwd", element: <ResetPwd />},
            {path: "politica", element: <PoliticaPrivacidade />},
            {index: true, element: <Login />},
            {path: "*", element: <Navigate to="/" />}
        ];
    }

    return createBrowserRouter([
        {
            path: "*",
            errorElement: <Error />,
            element: <NavBar />,
            children: routes
        },
    ]);
};